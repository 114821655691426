import { api } from "@/api/list";
export async function changeInfoTotal(
  eventId: number | string,
  event: string,
  type: number
) {
  const getChangeInfoTotal = (api as { getChangeInfoTotal }).getChangeInfoTotal;
  const res = await getChangeInfoTotal({ eventId, event, type });
  return res;
}
