import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { ArrowLeft } from "@element-plus/icons";
export default defineComponent({
  components: {
    ArrowLeft: ArrowLeft
  },
  props: {
    title: {
      type: String,
      default: "1",
      require: true
    },
    back: {
      type: Boolean,
      default: false
    },
    isranking: {
      type: Boolean,
      default: false
    }
  },
  emits: ["ranking"],
  setup: function setup(por, context) {
    var route = useRouter();

    function backfun() {
      route.back();
    }

    function paihang() {
      context.emit("ranking");
    }

    return {
      backfun: backfun,
      paihang: paihang
    };
  }
});